import React, { useMemo } from 'react'
import { SectionComponentType } from '../../constants/sections'
import SectionLayout from '../../layouts/section-layout/SectionLayout'
import renderSection, { ISectionData } from '../../utils/renderSection'
import { IDefaultSectionData } from '../page-sections/default-section'
import { IGridSectionData } from '../page-sections/grid-section'
import { ITabsSectionData } from '../page-sections/tabs-section'
import { LazyProvider } from '../../contexts/lazy-provider/LazyProvider'
import { ISimpleCardsSectionData } from '../page-sections/cards-section/simple-cards-section/SimpleCardsSection'
import { SectionsProvider } from '../../contexts/sections-provider/SectionsProvider'

export interface ISectionComponentProps {
  sections: Array<ISectionData>
}

const PageMainContent: React.FC<ISectionComponentProps> = ({ sections }) => {
  const filteredSections = useMemo(() => {
    const layoutSectionIds = new Set()
    sections.forEach(section => {
      if (section.strapi_component === SectionComponentType.TABS_LAYOUT) {
        const sectionData = section as ITabsSectionData
        sectionData.tabs.forEach(tab => layoutSectionIds.add(tab.sectionId))
      }
      if (section.strapi_component === SectionComponentType.GRID_LAYOUT) {
        const sectionData = section as IGridSectionData
        sectionData.blocks.forEach(block => layoutSectionIds.add(block.sectionId))
      }
      if (section.strapi_component === SectionComponentType.DEFAULT_SECTION) {
        const sectionData = section as IDefaultSectionData
        if (sectionData.contentId) {
          layoutSectionIds.add(sectionData.contentId)
        }
      }
      if (section.strapi_component === SectionComponentType.SIMPLE_CARDS_SECTION) {
        const sectionData = section as ISimpleCardsSectionData
        if (sectionData.customCardId) {
          layoutSectionIds.add(sectionData.customCardId)
        }
      }
    })
    return sections.filter(section => !layoutSectionIds.has(section.options.anchor))
  }, [ sections ])

  return (
    <SectionsProvider sections={sections}>
      {filteredSections.map((sectionData, index) => (
        <LazyProvider isLazy={index > 1} key={index}>
          <SectionLayout options={sectionData.options} section={sectionData}>
            {renderSection(sectionData)}
          </SectionLayout>
        </LazyProvider>
      ))}
    </SectionsProvider>
  )
}

export default PageMainContent
